import React from 'react';

export function IconNav(): JSX.Element {
  return (
    <nav className="icon-navigation">
      <a href="/help.html" title="Help">
        <svg aria-label="click for help" viewBox="0 0 99.083 100">
          <style>* {'{'} fill: var(--primary-background); {'}'}</style>
          <path d="M49.54 0C22.2-.018.026 22.375 0 49.974.025 77.62 22.198 100.01 49.54 100c27.355.01 49.524-22.38 49.543-50.026C99.063 22.374 76.893-.018 49.54 0zm0 88.48c-21.04 0-38.096-17.23-38.074-38.507C11.443 28.74 28.5 11.513 49.54 11.52c21.05-.008 38.11 17.222 38.132 38.454C87.65 71.25 70.59 88.48 49.542 88.48z"/>
          <path d="M44.402 67.657l11.198-.05V58.95c-.026-2.35 1.068-4.572 4-6.49 2.94-1.93 11.13-5.833 11.143-16.117-.013-10.245-8.615-17.3-15.85-18.82-7.224-1.474-15.057-.49-20.604 5.625-4.99 5.427-6.04 9.742-6.06 19.254h11.196v-2.217c-.022-5.062.565-10.414 7.79-11.9 3.973-.776 7.68.463 9.896 2.6 2.498 2.473 2.517 8.014-1.513 10.76l-6.276 4.274c-3.655 2.367-4.913 4.982-4.922 8.81v12.926zm-.052 15.85V72.04h11.303v11.466z"/>
        </svg>
      </a>
    </nav>
  );
}
